import React, { Fragment } from "react";
import socialNetworks from "../../data/SocialNetworks/socials.json";

const SocialLink = ({ socialClass = "member-social-icons" }) => {
  let networkTitle = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Fragment>
      <div className={`${socialClass} mt-30`}>
        {socialNetworks.map((social) => (
          <a
            title={networkTitle(social.networkName)}
            key={social.id}
            href={`https://${social.networkName}.com/${social.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`fab fa-${social.networkName}`} />
          </a>
        ))}
      </div>
    </Fragment>
  );
};

export default SocialLink;
