import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";

/*
 * @ All pages Import
 */
import Home from "./pages/Home";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Blogs from "./pages/Blogs";
import BlogDetailsPage from "./pages/BlogDetails";
import Doctors from "./pages/Doctors";
import Doctor from "./pages/DocDetails";
import DocBangalore from "./pages/DocBangalore";
import DocRamanagara from "./pages/DocRamanagara";
import DocFertility from "./pages/DocFertility";
import DocFertilityBengaluru from "./pages/DocFertilityBengaluru";
import DocFertilityRamanagara from "./pages/DocFertilityRamanagara";
import DocFertilityMysore from "./pages/DocFertilityMysore";
import DocClinics from "./pages/DocClinics";
import DocClinicsBanashankari from "./pages/DocClinicsBanashankari";
import DocClinicsNagarabhavi from "./pages/DocClinicsNagarabhavi";
import DocDentistry from "./pages/DocDentistry";
import DocKangarooKids from "./pages/DocKangarooKids";
import DocMysore from "./pages/DocMysore";
import Downloads from "./pages/Downloads";
import PackageDetailsMedia from "./pages/PackageDetailsMedia";
import Views360 from "./pages/360Views";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Facilities from "./pages/Facilities";
import PressRelease from "./pages/PressRelease";
import PressReleaseV2 from "./pages/PressReleaseV2";
import Kats from "./pages/Kats";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import NewBeginnings from "./pages/NewBeginnings";
import MissionVisionValues from "./pages/MissionVisionValues";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import OurJourney from "./pages/OurJourney";
import AdminTeam from "./pages/AdminTeam";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import Error404 from "./pages/Error404";
import BookAppointment from "./pages/BookAppointment";
import BookAppointmentMysore from "./pages/BookAppointmentMysore";
import DoctorAppointment from "./pages/DoctorAppointment";
import ScrollToTop from "./helpers/ScrollToTop";
import CentreDetails from "./pages/CentreDetails";
import PackageDetail from "./pages/PackageDetailPage";
import Testimonal from "./pages/Testimonal";
import Popup from "./components/Assets/Popup/Popup";
import SitemapPage from "./pages/SitemapPage";
import EventPage from "./pages/Events";
import Camp from "./pages/Camp";
import DeptDoctors from "./pages/DeptDoctors";
import GalleryVideo from "./pages/GalleryVideo";
import MysorePage from "./pages/MysorePage";
import PolicyPage from "./pages/PolicyPage";
import Test from "./pages/Test";
import TestComp from "./pages/TestComp";
import PeaceWithPcos from "./pages/PeaceWithPcos";
import SeniorWomenWellness from "./pages/SeniorWomenWellness";
import Appiontment from "./Appiontment/Index";
import Widgets from "./Widget";
import TrainingPage from "./pages/Training";
import LeftSidebar from "./templates/Sidebar/LeftSidebar/LeftSidebar";
import BottomBar from "./templates/Sidebar/BottomBar/BottomBar";
import Redirection from "./helpers/SEO/Redirections/Redirection";
import BookAppointmentTest from "./pages/BookAppointmentTest";
import SingleAppointmentTest from "./pages/SinglelAppointmentTest";
import PageThankYou from "./pages/ThankYou";

function RedirectWithStatus({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.status = status;
        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

const App = () => {
  // useEffect(() => {
  //   // ReactGA.initialize("UA-36714575-8");
  //   ReactGA.initialize("UA-168614877-1");
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <Router>
      <Redirection />
      <ScrollToTop>
        {/* <Widgets /> */}
        <LeftSidebar />
        <BottomBar />

        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <RedirectWithStatus
            status={301}
            from="/services"
            to="/specialities"
          />{" "}
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + "/appointment-booking"}`}
            component={Appiontment}
          /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/specialities"}`}
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service/:serviceID"}`}
            component={ServiceDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service/:serviceID/:id"}`}
            component={ServiceDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/specialities/:serviceID/:id"}`}
            component={ServiceDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blogs}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blogs/:blogID"}`}
            component={BlogDetailsPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blogs/:blogUrl/:blogID"}`}
            component={BlogDetailsPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors"}`}
            component={Doctors}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/bangalore"}`}
            component={DocBangalore}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/ramanagara"}`}
            component={DocRamanagara}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/mysore"}`}
            component={DocMysore}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/fertility-center"}`}
            component={DocFertility}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/doctors/fertility-center-bengaluru"
            }`}
            component={DocFertilityBengaluru}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/doctors/fertility-center-ramanagara"
            }`}
            component={DocFertilityRamanagara}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/doctors/fertility-center-mysore"
            }`}
            component={DocFertilityMysore}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/clinics"}`}
            component={DocClinics}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/clinics/banashankari"}`}
            component={DocClinicsBanashankari}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/clinics/nagarabhavi"}`}
            component={DocClinicsNagarabhavi}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/dentistry"}`}
            component={DocDentistry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/doctors/kangaroocare-kids"}`}
            component={DocKangarooKids}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/doctor/:docID"}`}
            component={Doctor}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/doctor/:docName/:id"}`}
            component={Doctor}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/gallery/facilities"}`}
            component={Facilities}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/gallery/new-beginnings"}`}
            component={NewBeginnings}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about/mission-vision-values"}`}
            component={MissionVisionValues}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about/board-of-directors"}`}
            component={BoardOfDirectors}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about/our-journey"}`}
            component={OurJourney}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about/admin-team"}`}
            component={AdminTeam}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/media/press-release"}`}
            component={PressRelease}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/media/downloads"}`}
            component={Downloads}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/packages"}`}
            component={PackageDetailsMedia}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/360-views"}`}
            component={Views360}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/media/faqs"}`}
            component={FrequentlyAskedQuestions}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/careers"}`}
            component={Careers}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/policy/privacy-policy"}`}
            component={PolicyPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/policy/terms-and-conditions"}`}
            component={PolicyPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/sitemap"}`}
            component={SitemapPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/kats"}`}
            component={Kats}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/book-appointment-test"}`}
            component={BookAppointment}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/book-appointment"}`}
            component={BookAppointmentTest}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/book-appointment/:branchValue"
            }`}
            component={SingleAppointmentTest}
          />
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + "/book-appointment/mysore"}`}
            component={BookAppointmentMysore}
          /> */}
          <Route path={`${process.env.PUBLIC_URL + "/appointment/:docID"}`}>
            <Redirect to="/book-appointment" />
          </Route>
          {/* component={DoctorAppointment} */}
          <Route
            path={`${process.env.PUBLIC_URL + "/test"}`}
            component={Test}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/centre/:centerName"}`}
            component={CentreDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/package/:packageName"}`}
            component={PackageDetail}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/video-testimonals"}`}
            component={Testimonal}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/media/camp"}`}
            component={Camp}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/media/events"}`}
            component={EventPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/media/training"}`}
            component={TrainingPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery/videos"}`}
            component={GalleryVideo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery/customer-testimonal"}`}
            component={GalleryVideo}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/doctors/:city/:specialityName"
            }`}
            component={DeptDoctors}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/ivf-center-mysore"}`}
            component={MysorePage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gynaecology-hospital-mysore"}`}
            component={MysorePage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/maternity-hospital-mysore"}`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/neonatal-intensive-care-unit-mysore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/best-fertility-center-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/best-ivf-center-in-bangalore"}`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL +
              "/best-hospitals-for-obstetrics-and-gynecology-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL +
              "/best-gynaecologist-hospitals-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL +
              "/best-hospital-for-pregnancy-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/best-maternity-care-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/good-maternity-hospital-in-bangalore"
            }`}
            component={MysorePage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/peace-with-pcos"}`}
            component={PeaceWithPcos}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/senior-women-wellness"}`}
            component={SeniorWomenWellness}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/thank-you"}`}
            component={PageThankYou}
          />
          <Route exact component={Error404} />
        </Switch>
        <Popup />
      </ScrollToTop>
    </Router>
  );
};

export default App;
