import React from "react";
import styles from "./imagePopUp.module.css";
import Mainimg from "../../assets/img/media/press/press-new-3.jpeg";
import { AiFillCloseCircle } from "react-icons/ai";
export default function ImagePopUp({ img, setImage, image }) {
  return (
    <div className={styles.wrapper}>
      <div onClick={() => setImage("")} className={styles.overlay}></div>
      {/* <img src={Mainimg} /> */}
      <img src={require("../../assets/img/media/press/" + image)} />

      <AiFillCloseCircle onClick={() => setImage("")} />
    </div>
  );
}
