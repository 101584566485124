import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
// import { Link } from "react-router-dom";
// import Logo from "../../assets/img/logo-dark.png";
import Logo from "../../assets/img/kangaroo-care-new-logo.png";
import ServicesList from "../../data/Services/allservices.json";

import SocialLink from "../../templates/Contact/SocialLink";

function Footer() {
  const pathname = window.location.pathname;

  const CenterLinks = [
    "/centre/bangalore",
    "/centre/mysore",
    "/centre/fertility-center/bengaluru",
    "/centre/kangaroo-care-dentistry",
    "/centre/kangaroo-care-clinics-nagarabhavi",
    "/centre/kangaroo-care-clinics-banashankari",
    "/centre/kangaroo-care-ramanagara",
    "/centre/fertility-center-mysore",
  ];
  // console.log("start");
  // console.log(pathname);
  // console.log(CenterLinks);
  // console.log(CenterLinks.includes(pathname));
  // console.log();
  // console.log("end");

  // if () {
  //   console.log("found");
  // }
  if (pathname == "/centre/bangalore") {
    console.log("done");
    console.log(pathname == "/centre/bangalore");
  }
  console.log();
  return (
    <footer className="footer-area ">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <a
                  title="Kangaroo Care - Women & Child Hospital"
                  href={`${process.env.PUBLIC_URL + "/"}`}
                >
                  <img src={Logo} alt="Logo" />
                </a>

                <Text>
                  Kangaroo Care is a Women and children hospital in Bangalore,
                  Mysore and Ramanagara. We are supported with top-rated doctors
                  and equipped with advanced infrastructure.
                </Text>

                {/* <h5 className="ts30">
                  <h3 style={{ fontSize: " 1.25rem" }}></h3>
                  Trusted by <strong>20000+</strong> Families{" "}
                </h5> */}

                <h3 style={{ fontSize: " 1.25rem" }} className="ts30">
                  {" "}
                  Trusted by <strong>30000+</strong> Families{" "}
                </h3>
                <SocialLink socialClass={"member-social-icons-footer"} />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Important Links">
              <List classes="widget-list">
                <LI>
                  <a title="Home" href={`${process.env.PUBLIC_URL + "/"}`}>
                    Home
                  </a>
                </LI>
                <LI>
                  <a
                    title="About"
                    href={`${process.env.PUBLIC_URL + "/about"}`}
                  >
                    About
                  </a>
                </LI>
                <LI>
                  <a
                    title="Our Journey"
                    href={`${process.env.PUBLIC_URL + "/about/our-journey"}`}
                  >
                    Our Journey
                  </a>
                </LI>
                <LI>
                  <a
                    title="Facilities"
                    href={`${process.env.PUBLIC_URL + "/gallery/facilities"}`}
                  >
                    Facilities
                  </a>
                </LI>
                <LI>
                  <a
                    title="Gallery"
                    href={`${process.env.PUBLIC_URL + "/gallery"}`}
                  >
                    Gallery
                  </a>
                </LI>
                <LI>
                  <a title="KATS" href={`${process.env.PUBLIC_URL + "/kats"}`}>
                    KATS
                  </a>
                </LI>
                <LI>
                  <a title="Blogs" href={`${process.env.PUBLIC_URL + "/blog"}`}>
                    Blogs
                  </a>
                </LI>
                <LI>
                  <a
                    title="FAQs"
                    href={`${process.env.PUBLIC_URL + "/media/faqs"}`}
                  >
                    FAQs
                  </a>
                </LI>
                <LI>
                  <a
                    title="Careers"
                    href={`${process.env.PUBLIC_URL + "/careers"}`}
                  >
                    Careers
                  </a>
                </LI>
                <LI>
                  <a
                    title="Sitemap"
                    href={`${process.env.PUBLIC_URL + "/sitemap"}`}
                  >
                    Sitemap
                  </a>
                </LI>
                <LI>
                  <a
                    title="Privacy Policy"
                    href={`${
                      process.env.PUBLIC_URL + "/policy/privacy_policy"
                    }`}
                  >
                    Privacy Policy
                  </a>
                </LI>
                <LI>
                  <a
                    title="Terms & Conditions"
                    href={`${
                      process.env.PUBLIC_URL + "/policy/Terms_&_Conditions"
                    }`}
                  >
                    Terms & Conditions
                  </a>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Services">
              <List classes="widget-list">
                {ServicesList.map(({ id, link, title, footerTitle = "" }) => (
                  <LI key={id}>
                    {footerTitle && (
                      <a title={title} href={link}>
                        {footerTitle}
                      </a>
                    )}
                  </LI>
                ))}
              </List>
            </Widget>
          </div>

          <div className="col">
            {!CenterLinks.includes(pathname) ||
              pathname == "/centre/bangalore" ||
              pathname == "/centre/mysore"}

            {(pathname == "/centre/bangalore" ||
              pathname == "/centre/fertility-center/bengaluru") && (
              <>
                <Widget title="Bangalore Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #89, 17th cross, MC layout, Near Balagangadharnath Swamy
                        Metro Station, Vijayanagar, Bangalore 560040.
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-envelope contact-icon"></i>
                      </div>
                      <div className="col-10">
                        <a
                          href="mailto:info@kangaroocareindia.com"
                          className="basetext"
                        >
                          info@kangaroocareindia.com
                        </a>
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                          {/* <div className="col-5">
                        <a
                          title="+91 8296688887"
                          href="tel:+918296688887"
                          className="basetext"
                        >
                          +91 8296688887
                        </a>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}
            {pathname == "/centre/kangaroo-care-ramanagara" && (
              <>
                {" "}
                <Widget title="Ramanagara Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                        Ramanagara -562 159 Karnataka
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-envelope contact-icon"></i>
                      </div>
                      <div className="col-10">
                        <a
                          href="mailto:info@kangaroocareindia.com"
                          className="basetext"
                        >
                          info@kangaroocareindia.com
                        </a>
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone fa-rotate-90 contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title="1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}
            {pathname == "/centre/mysore" && (
              <>
                {" "}
                <Widget title="Mysore Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA
                        Complex, Mysore - 570017.
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone fa-rotate-90 contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}

            {!CenterLinks.includes(pathname) && (
              <>
                <Widget title="Bangalore Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #89, 17th cross, MC layout, Near Balagangadharnath Swamy
                        Metro Station, Vijayanagar, Bangalore 560040.
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-envelope contact-icon"></i>
                      </div>
                      <div className="col-10">
                        <a
                          href="mailto:info@kangaroocareindia.com"
                          className="basetext"
                        >
                          info@kangaroocareindia.com
                        </a>
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                          {/* <div className="col-5">
                        <a
                          title="+91 8296688887"
                          href="tel:+918296688887"
                          className="basetext"
                        >
                          +91 8296688887
                        </a>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>

                <Widget title="Mysore Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA
                        Complex, Mysore - 570017.
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>

                <Widget title="Ramanagara Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                        Ramanagara -562 159 Karnataka
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}

            {pathname == "/centre/kangaroo-care-clinics-nagarabhavi" && (
              <>
                <Widget title="Nagarabhavi Clinic Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #236, 4th cross, RHCS layout, Annapoorneshwari Nagar
                        Main road , Nagarabhavi Bangalore - 560091
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}
            {pathname == "/centre/fertility-center-mysore" && (
              <>
                <Widget title="Mysore Fertility Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #384, New Kalidasa Road, Opposite Kangaroo Care
                        Hospitals, Vijayanagar, 1st Stage, Mysore - 570017
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}
            {pathname == "/centre/kangaroo-care-clinics-banashankari" && (
              <>
                <Widget title="Banashankari Clinic Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #23, Katriguppa Main Road, Opp. Big Bazar, B.S.K. 3rd
                        Stage, Bangalore-85
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}

            {pathname == "/centre/kangaroo-care-dentistry" && (
              <>
                <Widget title="Kangaroo Care Dentistry  Contact Info">
                  <address>
                    <div className="row">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-map-marker contact-icon"></i>
                      </div>
                      <div className="col-10 basetext">
                        #119, 8th cross MC Layout,Vijaynagar, Bengaluru
                      </div>
                    </div>
                    <div className="row ts20">
                      <div className="col-1 hide-mobile">
                        <i className="fa fa-phone contact-icon"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-10">
                            <a
                              title=" 1800-425-4500"
                              href="tel:1800-425-4500"
                              className="basetext"
                            >
                              Toll Free &nbsp; 1800-425-4500
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </address>
                </Widget>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mr-auto ts20 footer-text">
            &copy; Copyright 2024. All Rights Reserved. Maintained by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Team"
              href="https://digikit.in/"
              style={{ color: " rgb(238, 90, 42)" }}
            >
              DIGIKIT
            </a>
            , Partnered by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Team"
              href="https://turntech.in/"
              style={{ color: " rgb(238, 90, 42)" }}
            >
              Turntech India
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
