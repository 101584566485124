import React, { useState } from "react";
import TestimonialItem from "./TestimonialItem";
import SectionTitle from "../../UI/SectionTitle";

import TestimonialData from "../../../data/Testimonials/home-one.json";
import SlickSlider from "../../UI/Slick";

function Testimonials({ testimonial }) {
  const settings = {
    slidesToShow: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    className: "testimonial-content--3 testimonial-grid",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-area bg-offwhite sp-y sm-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-auto text-center">
            <SectionTitle
              title="Testimonials"
              heading=""
              image={require("../../../assets/img/separator4.png")}
            />
          </div>
        </div>

        <div className="row align-items-center testimonials_home">
          <div className="col-lg-11 m-auto">
            <div className="testimonial-content-wrap m-0 pl-0">
              <SlickSlider settings={settings}>
                {testimonial?.map((testimonial) => (
                  // <div key={testimonial.id}>
                  //   <TestimonialItem
                  //     author={<h3>{testimonial.author}</h3>}
                  //     authorThumb={testimonial.authorThumb}
                  //     quote={testimonial.quote}
                  //   />
                  // </div>
                  <div key={testimonial.id} className="testimonial_item_wrap">
                    <div className="testimonial-item testimonial-item--3 testimonials_new">
                      <div className="testimonial_item_img">
                        <img src="/images/google-01.png" />
                        <img src="/images/5-star.png" />
                      </div>
                      <div className="testimonial-txt">
                        <p>{testimonial.quote}</p>
                        <h5 className="client-name">
                          <h3>{testimonial.author}</h3>
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
              </SlickSlider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
