import React, { useState } from "react";
import PressRelease from "./Pr";
import prData from "../../data/PressRelease/index.json";

import ImagePopUp from "../../components/ImagePopUp/ImagePopUp";
const PRPage = () => {
  const [image, setImage] = useState("");
  console.log(image);
  return (
    <>
      {image && (
        <ImagePopUp image={image} setImage={setImage} img="press-new-1.jpeg" />
      )}
      <div className="press-release-wrapper sm-top sm-bottom">
        <div className="container">
          <div className="row">
            {prData.map((prd) => (
              <PressRelease
                key={prd.id}
                id={prd.id}
                title={prd.title}
                date={prd.date}
                pressImage={prd.image}
                image={image}
                setImage={setImage}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PRPage;
