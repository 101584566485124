import React from "react";
import { Link } from "react-router-dom";
import nabh from "../../assets/img/nabh2.png";
function HeaderConfig(props) {
  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector(".off-canvas-menu");
    offCanvasMenu.classList.add("active");
  };

  return (
    <div className="header-action text-right">
      <div className="row header-top-wrap text-brand d-flex align-items-center justify-content-end pr-5">
        <div>
          {/* <a
            href="tel:18004254500"
            className="emnumber  d-xs-block d-md-block d-lg-block text-left"
          >
            <i className="fa fa-phone-alt"></i>
            Toll Free - 1800-425-4500
          </a> */}
          {/* 
          <a
            href="tel:+917996711177"
            className="secondNumber d-xs-block d-md-block d-lg-block text-left"
          >
            <i className="fa fa-phone"></i>
            Mysuru<p> - 0821-2433030</p>
          </a>

          <a
            href="tel:+918212433030"
            className="thirdNumber d-xs-block d-md-block d-lg-block text-left"
          >
            07996711177
          </a> */}
        </div>

        <a
          href="http://kangaroocarefertility.com/"
          className="tel-no kangaroocare-fertility  d-none d-md-block ml-4"
          title="Kangaroocare Fertility"
          target="_blank"
        >
          kangaroocare Fertility
        </a>
        <Link to={"/kats"} className="tel-no kats " title="K A T S">
          K A T S
        </Link>
        {/* <Link
          to={"/book-appointment"}
          className="tel-no ml-auto d-none d-md-block"
          title="Appointments"
        >
          Appointments
        </Link> */}
        {/* 	//	OLD
            <a
            href="https://www.kangaroocare.app"
            className="tel-no ml-auto d-none d-md-block"
            >
            Appointments
            </a>
        */}
        <a
          href="https://kcprematurebabies.com/"
          className="tel-no prematureBabyFoundation d-none d-md-block"
          title="Premature Baby Foundation"
        >
          Premature Baby Foundation
        </a>

        <img
          src={nabh}
          className="nabh-logo"
          alt="patient safety and quality of care"
        />
        <button
          className="btn btn-menu d-sm-block d-md-none"
          onClick={MobileMenuHandler}
        >
          <i className="fa fa-bars"></i>
        </button>
      </div>
    </div>
  );
}

export default HeaderConfig;
