import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Services from "../components/ServicesPage";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";
import { Link } from "react-router-dom";

const PageThankYou = () => {
  return (
    <Fragment>
      <Header />
      <section className="thankyou">
        <h1>
          Thanks for connecting with us
          <br />
          will get in touch with shortly
        </h1>
        <Link to="/">Go Back</Link>
      </section>
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageThankYou;
